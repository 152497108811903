import CompRebateLayoutTwo from '@/components/rebate/comp-rebate-layout-two/index.vue';
import RebateTable from '@/components/rebate/rebate-table/index.vue';
import rebateMixin from '@/mixins/nov-new-rebate';
import { getTeamMarkDetail } from '@/service/rebate/novNewRebate';
import Rmb from '@/components/common/rmb/index.vue';

export default {
    name: 'nov-new-rebate-reach-award',
    mixins: [rebateMixin],
    components: {
        CompRebateLayoutTwo,
        RebateTable,
        Rmb,
    },
    data() {
        return {};
    },
    computed: {
        //  直推团队达标折扣
        directColumns() {
            const columns = [
                {
                    label: '团队',
                    key: 'name',
                    render(value) {
                        return `${value}团队`;
                    },
                },
                {
                    label: '总体团购金额',
                    isRmb: true,
                    key: 'personMarkMoney',
                },
                {
                    label: '折扣系数',
                    key: 'coefficient',
                    render(value) {
                        return value * 100 + '%';
                    },
                },
                {
                    label: '团队达标折扣',
                    isRmb: true,
                    key: 'recommendTeamAward',
                },
            ];
            return columns;
        },

        //  直推团队达标折扣列表
        directList() {
            if (this.rebateInfo) {
                return this.rebateInfo.teamList || [];
            }
            return [];
        },

        // 直推团队达标折扣 小计
        directBonusSum() {
            if (this.rebateInfo) {
                const teamList = this.rebateInfo.teamList || [];
                return teamList.reduce((sum, val) => {
                    return Number(sum) + Number(val.recommendTeamAward);
                }, 0);
            }
            return '--';
        },
    },

    methods: {
        //  初始化页面
        init() {
            const {
                startTime,
                endTime,
                agentId,
                userInfo: { levelId },
                curActivityId,
                curSystemId,
            } = this;
            getTeamMarkDetail({
                startTime,
                endTime,
                userId: agentId,
                levelId,
                systemId: curSystemId,
                actId: curActivityId,
            }).then(data => {
                this.rebateInfo = data;
            });
        },

        changeSelectedActivity(value) {
            this.curActivity = value;
        },

        changeSelectedSystem(value) {
            this.curSystem = value;
        },
    },
};
